<template>
  <v-container>
    <v-row v-if="city">
      <v-col cols="12" sm="10" lg="8">
        <h4 class="title">Operating City {{ city.city_name }}</h4>
      </v-col>
      <v-col cols="12" sm="10" lg="8">
        <v-card class="mx-auto" outlined>
          <v-card-text>
            <v-row align="center">
              <v-col cols="6" md="4" class="title">
                <v-text-field
                  v-model="city.city_name"
                  label="Name"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="4">
                <v-text-field
                  v-model="city.city_code"
                  label="Code"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="4">
                <v-checkbox
                  v-model="city.isVisible"
                  label="Visible"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="latitude"
                  label="Latitude"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="longitude"
                  label="Longitude"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="city.producerCount"
                  label="Producer Count"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12" class="title">
                <v-textarea
                  v-model="city.title"
                  rows="3"
                  label="Title"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12" class="title">
                <v-textarea
                  v-model="city.tagline"
                  rows="3"
                  label="Tagline"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12" sm="6" class="title">
                <v-textarea
                  v-model="city.image_url"
                  rows="3"
                  label="Image Url"
                ></v-textarea>
              </v-col>
              <v-col v-if="city.image_url" cols="12" sm="6">
                <v-img contain :src="city.image_url" alt="" />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn color="info" @click.stop="save">Save </v-btn>

            <v-spacer></v-spacer>
            <v-btn to="/admin/cities/list"> back </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col sm="6">
        <v-alert color="error" outlined>Not found</v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from 'firebase'

export default {
  data() {
    return {
      city: null,
      reply: null,
      longitude: null,
      latitude: null
    }
  },
  watch: {
    longitude() {
      if (this.latitude && this.longitude) {
        this.city.location = new firebase.firestore.GeoPoint(
          this.latitude,
          this.longitude
        )
      }
    },
    latitude() {
      if (this.latitude && this.longitude) {
        this.city.location = new firebase.firestore.GeoPoint(
          this.latitude,
          this.longitude
        )
      }
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      console.log('Loading city:', this.$route.params.id)
      this.$store
        .dispatch('loadOperatingCity', { id: this.$route.params.id })
        .then(city => {
          console.log(city)
          city.id = this.$route.params.id
          if (!city.location) {
            city.location = new firebase.firestore.GeoPoint(0, 0)
          } else {
            this.latitude = city.location.latitude
            this.longitude = city.location.longitude
          }
          this.city = city
        })
    } else {
      this.city = { location: new firebase.firestore.GeoPoint(0, 0) }
    }
  },

  methods: {
    save() {
      console.log(this.city)

      this.$store.dispatch('saveOperatingCity', this.city)
    }
  }
}
</script>
